<!--
 * @Author: snltty
 * @Date: 2021-10-12 09:32:33
 * @LastEditors: snltty
 * @LastEditTime: 2021-10-12 11:04:21
 * @version: v1.0.0
 * @Descripttion: 功能说明
 * @FilePath: \pc\src\views\New.vue
-->
<template>
    <div class="new">
        <div class="head">
            <h3>{{item.Title}}</h3>
            <p class="time">{{item.AddDatetime}}</p>
        </div>
        <div class="content" v-html="item.Content"></div>
        <div class="end">
            <a href="javascript:history.go(-1);">返回</a>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { onMounted, reactive, toRefs } from 'vue-demi'
import { useRoute } from 'vue-router'
export default {
    setup () {

        const route = useRoute();
        const state = reactive({
            item: {
                Title: '',
                AddDatetime: '',
                Content: ''
            }
        });
        const loadData = () => {
            axios.get('https://api.qbcode.cn:8081/api/robotcar/news/get', {
                params: {
                    id: route.query.id,
                }
            }).then((res) => {
                if (res.data.State == 'Success') {
                    console.log(res.data.Data)
                    state.item = res.data.Data;
                }
            });
        }
        onMounted(() => {
            loadData();
        });
        return {
            ...toRefs(state)
        }
    }
}
</script>

<style lang="stylus">
.new
    width: 120rem;
    margin: 0 auto;
    max-width: 95%;

    .head
        border-bottom: 2px solid #000;

        .time
            font-size: 2rem;
            color: #666;
            text-align: right;
            padding: 2rem 0 1rem 0;

        h3
            color: #262626;
            font-size: 3rem;
            font-weight: 400;
            padding: 5rem 0 0 0;

    .content
        padding-top: 3rem;
        font-size: 2rem;
        color: #555;

        img
            max-width: 100%;

    .end
        text-align: center;
        padding: 3rem 0;

        a
            display: inline-block;
            padding: 1rem 3rem;
            border-radius: 2px;
            background-color: #bfbfbf;
            color: #262626;
            font-size: 1.6rem;
</style>